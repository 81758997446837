import React, { useState, useEffect } from 'react';
import './App.css';
import { db } from './firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import UrduCalendar from './UrduCalendar';
import IslamicBabyNames from './IslamicBabyNames';
import QuranicWords from './QuranicWords';
import IslamicBooks from './IslamicBooks';
import Quiz from './Quiz'; // Quiz Component
import NinetyNineNames from './NinetyNineNames'; // 99 Names of Allah Component
import IslamicCountries from './IslamicCountries'; // New import for IslamicCountries Component
import './QuranicWords.css';
import './IslamicBooks.css';
import './Quiz.css'; // Optional: Add styling for the quiz
import './NinetyNineNames.css'; // Styling for 99 Names of Allah (Optional)
import './IslamicCountries.css'; // Add styling for IslamicCountries
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import MuslimScientists from './MuslimScientists';
import IslamicFigures from './IslamicFigures';
import HaramFoods from './HaramFoods';
import HaramRelations from './HaramRelations';
import NotificationsForm from './NotificationsForm';

function App() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'posts'), (snapshot) => {
      const postsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(postsData);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <div className="App">
        <main>
          <UrduCalendar />
          <Routes>
            <Route path="/" element={<Quiz />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/quranic-words" element={<QuranicWords />} />
            <Route path="/islamic-baby-names" element={<IslamicBabyNames />} />
            <Route path="/islamic-books" element={<IslamicBooks />} />
            <Route path="/israel-palestine-updates" element={<IsraelPalestineUpdates />} />
            <Route path="/ninety-nine-names" element={<NinetyNineNames />} /> {/* New Route */}
            <Route path="/islamic-countries" element={<IslamicCountries />} /> {/* New Route */}
            <Route path="/muslim-scientists" element={<MuslimScientists />} /> {/* New Route */}
            <Route path="/islamic-figures" element={<IslamicFigures />} /> {/* New Route */}
            <Route path="/haram-foods" element={<HaramFoods />} /> {/* New Route */}
            <Route path="/haram-relations" element={<HaramRelations />} /> {/* New Route */}
            <Route path="/ramadan-notifications" element={<NotificationsForm />} /> {/* New Route */}
            <Route path="/about-us" element={<AboutUs />} /> {/* About Us Route */}
          </Routes>
        </main>

        {/* Left Navigation for Desktop */}
        <nav className="left-nav">
          <NavLink to="/quiz" activeClassName="active-link">
            Quiz
          </NavLink>
          <NavLink to="/ninety-nine-names" activeClassName="active-link">
            99 Names of Allah
          </NavLink>
          <NavLink to="/quranic-words" activeClassName="active-link">
            Quranic Words
          </NavLink>
          <NavLink to="/islamic-baby-names" activeClassName="active-link">
            Islamic Baby Names
          </NavLink>
          <NavLink to="/islamic-books" activeClassName="active-link">
            Islamic Books
          </NavLink>
          <NavLink to="/israel-palestine-updates" activeClassName="active-link">
            Israel-Palestine Live Updates
          </NavLink>
          <NavLink to="/islamic-countries" activeClassName="active-link">
            Islamic Countries
          </NavLink>
          <NavLink to="/muslim-scientists" activeClassName="active-link">
            Muslim Scientists
          </NavLink>
          <NavLink to="/islamic-figures" activeClassName="active-link">
            Islamic Figures
          </NavLink>
          <NavLink to="/haram-foods" activeClassName="active-link">
            Haram Foods
          </NavLink>
          <NavLink to="/haram-relations" activeClassName="active-link">
            Haram Relations
          </NavLink>
          <NavLink to="/ramadan-notifications" activeClassName="active-link">
            Ramadan Notifications
          </NavLink>
          <NavLink to="/about-us" activeClassName="active-link">
            About Us
          </NavLink>
        </nav>

        {/* Bottom Navigation for Mobile */}
        <nav className="bottom-nav">
          <NavLink to="/quiz" activeClassName="active-link">
            Quiz
          </NavLink>
          <NavLink to="/ninety-nine-names" activeClassName="active-link">
            99 Names
          </NavLink>
          <NavLink to="/quranic-words" activeClassName="active-link">
            Quranic Words
          </NavLink>
          <NavLink to="/islamic-baby-names" activeClassName="active-link">
            Islamic Baby Names
          </NavLink>
          <NavLink to="/islamic-books" activeClassName="active-link">
            Islamic Books
          </NavLink>
          <NavLink to="/israel-palestine-updates" activeClassName="active-link">
            Updates
          </NavLink>
          <NavLink to="/islamic-countries" activeClassName="active-link">
            Countries
          </NavLink>
          <NavLink to="/muslim-scientists" activeClassName="active-link">
            Scientists
          </NavLink>
          <NavLink to="/islamic-figures" activeClassName="active-link">
            Figures
          </NavLink>
          <NavLink to="/haram-foods" activeClassName="active-link">
            Haram Foods
          </NavLink>
          <NavLink to="/haram-relations" activeClassName="active-link">
            Harams Relations
          </NavLink>
          <NavLink to="/about-us" activeClassName="active-link">
            About Us
          </NavLink>
        </nav>
      </div>
    </Router>
  );
}

// About Us Component
function AboutUs() {
  return (
    <div className="about-us-container">
      <h2>About Us</h2>
      <p>
        Welcome to our Islamic Learning Platform! We aim to provide authentic
        and engaging content to help you explore Islamic teachings, history, and
        culture. From Quranic words and Islamic baby names to the 99 Names of
        Allah and insights into Muslim scientists, we are here to make your
        learning journey enriching and enjoyable.
      </p>
      <p>
        Whether you're looking for knowledge, inspiration, or practical guidance,
        our resources are designed to serve Muslims and non-Muslims alike. Thank
        you for being a part of our community.
      </p>
      {/* <p>Contact us at <a href="mailto:support@islamiclearning.com">support@thequran.club.com</a></p> */}
    </div>
  );
}

// Updated Israel-Palestine Live Updates Component
function IsraelPalestineUpdates() {
  return (
    <div className="updates-container">
      <h2>Israel-Palestine Live Updates</h2>
      <iframe
        src="https://israelpalestine.liveuamap.com/"
        width="100%"
        height="600px"
        title="Israel-Palestine Map Canvas"
        frameBorder="0"
        className="map-iframe"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default App;
