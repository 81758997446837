import React, { useState } from 'react';
import './NotificationsForm.css';

function NotificationsForm() {
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    location: '',
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send data to backend
    try {
      const response = await fetch('/api/notifications', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus('Notification setup successful!');
        setFormData({ name: '', number: '', location: '' });
      } else {
        setStatus('Error setting up notifications.');
      }
    } catch (error) {
      console.error(error);
      setStatus('Error connecting to the server.');
    }
  };

  return (
    <div className="notifications-form">
      <h2>Set Up WhatsApp Notifications</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>WhatsApp Number</label>
          <input
            type="tel"
            name="number"
            value={formData.number}
            onChange={handleChange}
            required
            placeholder="e.g., +1234567890"
          />
        </div>
        <div>
          <label>Location</label>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Set Up Notifications</button>
      </form>
      {status && <p>{status}</p>}
    </div>
  );
}

export default NotificationsForm;
